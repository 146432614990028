// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kebab-menu {
  position: absolute;
  inset-inline-end: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./styles/KebabMenu.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,MAAM;EACN,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".kebab-menu {\n  position: absolute;\n  inset-inline-end: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
